.main_sidebar_wrapper {
  width: 14rem;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0.125rem 0 0.625rem rgba(0, 0, 0, 0.04);
}

.inside_main_sidebar_wrapper {
  display: grid;
  width: 100%;
}

.logo_wrapper {
  padding: 16px 8px;
}

.logo_wrapper img {
  width: 100%;
}

.menu_wrapper {
  padding: 16px 0px;
}

.menu_wrapper_single_item {
  margin: 8px;
  padding: 8px;
}

.menu_wrapper_single_item:hover {
  background-color: lightcyan;
  border-right: 4px solid rgb(162, 245, 245);
  border-radius: 4px;
}
