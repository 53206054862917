.hero-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #ffffcd;
}
.hero-section-left {
  font-size: 36px;
}

@media screen and (max-width: 650px) {
  .hero-section {
    display: grid;
    grid-template-columns: 1fr;
    gap: 48px;
    background-color: #ffffcd;
  }
  .hero-section-left {
    padding-top: 48px;
    font-size: 28px;
  }
}

.search-content {
  /* display: none; */
  position: absolute;
  top: 38px;
  width: 100%;
  height: 8rem;
  background-color: white;
  overflow-y: auto;
  border-radius: 4px;
}
.search-content::-webkit-scrollbar {
  width: 8px;
}
.search-content::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: rgba(169, 169, 169, 0.288);
  margin-right: 100px;
}

.search-content::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(138, 134, 134, 0.5);
}

.search-content a {
  display: block;
  padding: 5px;
  cursor: pointer;
}
