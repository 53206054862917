.skill:hover {
  background-color: #d3d0d0;
}

.skill-input {
  width: 100%;
  font-size: 18px !important;
}
@media screen and (max-width: 650px) {
  .skill-input {
    width: 100%;
    font-size: 18px !important;
  }
}