.signup-form-container {
  margin: 16px auto;
  width: 50%;
  padding: 3rem;
  border-radius: 8px;
}
.signup-form-heading {
  border-bottom: 1px solid rgb(180, 178, 178);
}
.signup-form-inner-scroll {
  height: 80vh;
  overflow-y: scroll;
}
.signup-form-inner::-webkit-scrollbar {
  width: 2px;
}
.signup-form-inner form div > span {
  font-size: 12px;
}
.signup-form-inner form div > label,
.signup-form-inner form div > input,
.signup-form-inner form div > span {
  font-size: 14px;
}
.signup-form-inner form div > label {
  font-weight: 500;
}
.signup-form-inner form div > span {
  color: red;
}
