.profile-image-container {
  height: 5rem;
  width: 5rem;
}

.profile-image-container img {
  height: 100%;
  width: 100%;
  border-radius: 100%;
}
