.login-tabs {
  width: 50%;
  padding: 1.1rem;
  background-color: white;
  border: none;
}

.left-tab-active {
  box-shadow: 0px -2px rgb(230, 225, 225), 2px -2px rgb(230, 225, 225),
    2px 0px rgb(230, 225, 225);
  z-index: 1;
}

.right-tab-active {
  box-shadow: 0px -2px rgb(230, 225, 225), -2px -2px rgb(230, 225, 225),
    -2px 0px rgb(230, 225, 225);
  z-index: 1;
}

.tab-inactive {
  border: none;
  box-shadow: 0px 2px rgba(174, 172, 172, 0.385);
}
