.portal-header {
  height: 8%;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.portal-header span {
  font-size: 22px;
  font-weight: 500;
}
