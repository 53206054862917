.mobile-header {
  display: none;
}

@media screen and (max-width: 750px) {
  .desktop-header {
    display: none;
  }
  .mobile-header {
    display: block;
  }
}
