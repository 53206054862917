.footer {
  background: linear-gradient(
    109.6deg,
    rgba(0, 0, 0, 0.93) 11.2%,
    rgb(63, 61, 61) 78.9%
  );
  color: white;
  text-align: left;
  padding: 1.5rem;
  font-size: 14px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin: 20px 0 20px 0;
}

.grid-col-1,
.grid-col-3,
.grid-col-2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.center-elements-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.center-elements {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-links {
  margin-top: 10px;
}
.footer-links-item {
  text-decoration: none;
  color: white;
}

@media only screen and (max-width: 768px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    margin: 20px 0 20px 0;
  }
  .center-elements-logo {
    display: flex;
    align-items: start;
    justify-content: start;
  }
  .grid-col-3 {
    display: flex;
    align-items: start;
    flex-direction: column;
  }
}
@media only screen and (max-width: 440px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin: 20px 0 20px 0;
  }
  .center-elements-logo {
    display: flex;
    align-items: start;
    justify-content: start;
  }
  .grid-col-3,
  .grid-col-2 {
    display: flex;
    align-items: start;
    flex-direction: column;
  }
}
