.list_table_wrapper {
  width: 100%;
  overflow: auto;
  font-size: 14px;
}

.fixed_header {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
}

.fixed_header thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f0f0f6;
}

.fixed_header tr {
  border-bottom: 1px solid #ddd9d9;
}

.fixed_header th,
.fixed_header td {
  padding: 10px;
}
