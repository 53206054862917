.main {
  background-color: #f6f6f6;
}

.main-content-division {
  margin-left: 14rem;
  background-color: rgb(213, 250, 215);
  height: 100vh;
}

.grid {
  height: 100%;
  display: grid;
  grid-template-rows: max-content auto;
}

.grid-inner-container {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_content_wrapper {
  display: grid;
  grid-template-rows: 1fr 1fr 8fr 1fr;
  /* justify-content: stretch; */
  background-color: white;
  height: 92%;
}

.content_wrapper {
  height: 100%;
  border-radius: 4px;
}

.search_filter_wrapper {
  border-bottom: 1px solid #f0f0f6;
}

.search_wrapper {
  max-width: 270px;
}

.list_wrapper {
  display: grid;
  padding: 16px;
}

.list_filters select {
  width: 10rem;
}
