.page-center-div-container {
  background-color: rgba(0, 0, 0, 0.65);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 12;
}
.page-center-div {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.overflow-wrapper {
  max-height: 60vh;
  overflow-y: auto;
}
.overflow-wrapper::-webkit-scrollbar {
  width: 8px;
}
.overflow-wrapper::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: rgba(211, 207, 207, 0.308);
  margin-right: 100px;
}
.overflow-wrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(83, 80, 80, 0.705);
}
.search-drop-down {
  padding: 8px;
  position: absolute;
  top: 40px;
  width: 100%;
  background-color: white;
  border: 1px solid rgb(202, 200, 200);
  border-radius: 8px !important;
  z-index: 100;
}
.search-list-item {
  padding: 0 4px;
}
.search-list-item:hover {
  cursor: pointer;
  background-color: rgb(240, 238, 238);
}
.search-list-item-heading {
  padding: 4px;
  text-align: center;
  font-weight: bolder;
  background-color: rgb(202, 200, 200);
}
